<template>
  <div>
    <trac-tab-window
      :sectionTitle="'Online Catalogue'"
      :subLinks="subLinks"
    />
  </div>
</template>

<script>
import MixinForScrollToTop from '../offline-module/mixins/TabbedMixin'
export default {
  mixins: [MixinForScrollToTop],
  data() {
    return {
      subLinks: [
        {
          name: "Online Store",
          path: "/tab-online-catalogue/online-catalogue",
          selected: false,
          routerWindowClassString: 'bg-white mt-12 p-12 big-shadow',
          dataAttribute: "online-store",
        },
        {
          name: "Online Orders",
          path: "/tab-online-catalogue/orders",
          selected: false,
          dataAttribute: "online-order",
        }
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
</style>